import DateHelper from '@/util/DateHelper'

let StatsBaseMonthGridMixin = {
  methods: {
    defaultTo: () => DateHelper.firstDayOfCurrentMonthAsString(),
    defaultFrom: () => DateHelper.firstDayOfMonthOneYearAgoAsString()
  }
}

export default StatsBaseMonthGridMixin
