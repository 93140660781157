<template>
  <v-menu v-if="defaultDate !== false" :close-on-content-click="true" min-width="auto" offset-y>
    <template v-slot:activator="{ on, attrs }">
      <DatePickerTextField
        :value="date"
        v-bind="attrs"
        :visibility="on"
        :label="label"
        :error-message="errorMessage"
        :hide-details="true"
        :disable-validation="true"
      />
    </template>
    <DatePicker
      @update_date="updateDate($event)"
      :value="date"
      :min-date="minDate"
      :max-date="maxDate"
      :allowed-dates="allowedDates"
      :no-title="true"
      type="month"
      :content-class="contentClass"
    />
  </v-menu>
</template>

<script>
import DatePicker from './DatePicker'
import DatePickerTextField from './DatePickerTextField'
import DateHelper from '../../util/DateHelper'

export default {
  name: 'MonthSelector',
  components: {
    DatePicker,
    DatePickerTextField
  },
  props: {
    label: {
      String,
      required: true
    },
    errorMessage: String,
    defaultDate: {
      String,
      required: true
    },
    minDate: {
      String
    },
    maxDate: {
      String
    },
    allowedDates: {
      type: Function,
      default: null
    },
    contentClass: {
      String
    }
  },
  data() {
    return {
      date: this.defaultDate
    }
  },
  methods: {
    updateDate(date) {
      this.date = date
      this.$emit('update_date', date)
    }
  },
  computed: {
    calculatedMinDate() {
      return DateHelper.toStringOrDefaultMinDate(this.minDate)
    }
  }
}
</script>
