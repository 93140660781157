<template>
  <div id="stats-domain-month">
    <StatsDomainMonth />
  </div>
</template>

<script>
import StatsDomainMonth from '../../../components/stats/domain/StatsDomainMonth'

export default {
  components: {
    StatsDomainMonth
  }
}
</script>
